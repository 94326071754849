import styled from "styled-components"

const Flag = styled.img`
  display: inline-block;
  width: 24px;
  padding: 0;
  margin: 0;
  margin-right: 1rem;
`

export default Flag
