import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import FooterStyled from "./styled/FooterStyled"
import FooterSection from "./styled/FooterSection"
import FooterSmallPrint from "./styled/FooterSmallPrint"

const Footer = ({ children, revision, browser }) => (
  <FooterStyled>
    <FooterSection>{children}</FooterSection>
    <FooterSmallPrint>
      <span>Revision: {revision}</span> ·{" "}
      <Link to="/impressum/">Impressum</Link> ·{" "}
      <Link to="/datenschutz/">Datenschutz</Link> ·{" "}
      <span>Browser: {browser}</span>
    </FooterSmallPrint>
  </FooterStyled>
)

export default Footer

Footer.propTypes = {
  children: PropTypes.any,
}
