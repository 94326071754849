import styled from "styled-components"
import theme from "../../../theme"
import breakpoints from "../../../theme/breakpoints"

const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: smaller;
  @media (min-width: ${breakpoints.sm}) {
    font-size: inherit;
  }
  padding: 1rem;
  background: ${theme.bgHeader || "#333"};
  color: #fff;
  & a {
    color: #fff;
  }
  & a:hover {
    color: #cfd8dd;
  }
  height: ${theme.footerHeight || "200px"};
`

export default FooterStyled
