import styled from "styled-components"
import theme from "../../../theme"

const FooterSmallPrint = styled.footer`
  display: block;
  font-size: small;
  line-height: 1.5;
`

export default FooterSmallPrint
